<template>
  <div
    style="width: 100vw;padding:0px 0px 20px 0px;margin:0px;position: relative;margin-left: -50vw;left: 50%;background-color: white;"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 pt-4">
          <div
            style="background-color: #0172B8;height: 58px;position: relative;"
          >
            <p
              style="font-family: 'kittithadabold75regular';font-size:32px; color: white; position: absolute;top: 60%;left: 50%;width: 100%;height: 100%;margin: -27px 0 0 -50%;text-align: center;"
            >
              เลือกจากชุดตู้เสื้อผ้าที่เราเตรียมไว้ให้คุณแล้ว
            </p>
          </div>
        </div>
        <div class="col-12 pt-1 text-center">
          <span>*ราคาอาจมีการเปลี่ยนแปลงตามการออกแบบ</span>
        </div>
      </div>
      <div class="row mt-1">
        <WardrobeShape :nameShape="`I-SHAPE`" :priceStart="`เริ่มต้น 11,327.-`" :imageShape="`/img/prodboard/i_shape/i1/white.jpg`" :linkShape="`i-shape`" />
        <WardrobeShape :nameShape="`L-SHAPE`" :priceStart="`เริ่มต้น 23,673.-`" :imageShape="`/img/prodboard/l_shape/l1/white.jpg`" :linkShape="`l-shape`"/>
        <WardrobeShape :nameShape="`U-SHAPE`" :priceStart="`เริ่มต้น 36,944.-`" :imageShape="`/img/prodboard/u_shape/u1/white.jpg`" :linkShape="`u-shape`"/>
      </div>
    </div>
  </div>
</template>

<script>
  import WardrobeShape from "@/components/Prodboard/WardrobeShape.vue";

  export default {
    name: "WardrobeTemplate",
    components: {
      WardrobeShape,
    },
  };
</script>

<style></style>
