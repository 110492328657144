<template>
  <div class="col-md-4 col-sm-12 mt-1">
    <p
      style="font-family: 'kittithadabold75regular'; text-align: center;padding: 0px;margin: 0px;font-size: 1.5em;"
    >
      {{ nameShape }}
    </p>
    <div style="background-color: white;">
      <img
        id="image_1"
        v-bind:src="`${imageShape}`"
        class="w-100 px-2 pr-0"
      />
    </div>
    <p
      style="text-align: center;margin-top: 5px;font-family: 'kittithadabold75regular';font-weight:bold;font-size:2em;margin-bottom: 0px;"
    >
      {{ priceStart }}
    </p>
    <div class="row">
      <div class="col-12 text-center p-0">
        <button
          @click="redirectShape(linkShape)"
          style="background-color: #EAAD21; color: black; border: none;border-radius: 4px;"
        >
          <img
            style="margin-top: -5px;width: 14%;margin-right: 5px;"
            src="/img/prodboard/cart.svg"
          />
          <span style="font-family: 'kittithadabold75regular';"
            >เลือกตู้เสื้อผ้า</span
          >
        </button>
      </div>
      <!-- <div class="col-6 text-center p-0">
        <button
          @click="prodboard"
          style="background-color: #EAAD21; color: black; border: none;border-radius: 4px;"
        >
          <img
            style="margin-top: -5px;width: 14%;margin-right: 5px;"
            src="/img/prodboard/LeadingIcon.svg"
          />
          <span style="font-family: 'kittithadabold75regular';"
            >ออกแบบเพิ่ม</span
          >
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
  import * as serviceMain from "@/services/main.service";
  export default {
    name: "WardrobeShape",
    props: ["nameShape", "priceStart", "imageShape", "linkShape"],
    methods: {
      // async prodboard() {
      //   try {
      //     this.$router.push({
      //       name: "Prodboard",
      //       params: { getProdID: 0 },
      //     });
      //   } catch (error) {
      //     serviceMain.showErrorAlert(this, error.message);
      //   }
      // },
      async redirectShape(linkShape) {
        try {
          this.$router.push({
            name: "ProdboardShapeListPage",
            params: { getProdShape: linkShape },
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
  };
</script>

<style scope>
  .item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 30%;
    background: none;
    border: none;
    padding: 0px;
  }

  .caption {
    display: block;
  }
</style>
