<template>
  <section>
    <div class="row mt-4 align-items-center">
      <div class="col-md-6 col-sm-12">
        <img src="/img/prodboard/wardrobe1.png" class="w-100 px-2 pr-0" />
      </div>
      <div class="col-md-6 col-sm-12">
        <h2 style="margin-top: 5%; margin-bottom: 0px;text-align: center;">
          <span
            style="font-weight: bold;font-family: kittithadabold75regular;font-size:1em;"
            >Wardrobe</span
          >
          <span style="font-family: kittithadathin35regular;font-size:1em;"
            >Designer</span
          >
        </h2>
        <div
          class="col-10 mx-auto"
          style="border-bottom: 1px dotted black;border-top: 1px dotted black;"
        >
          <p
            style="margin-top:1rem;font-family: kittithadathin35regular;text-align: center;"
          >
            เพราะเราอยากช่วยให้คุณมีตู้เสื้อผ้าที่ดีที่สุดที่เหมาะกับเสื้อผ้าและห้องของคุณ
            เรามีตู้เสื้อผ้าบานคู่และ ตู้ขนาดใหญ่ให้เลือกมากมาย
            มีหลายแบบหลายขนาด
            ตู้เสื้อผ้าส่วนใหญ่มีอุปกรณ์สำหรับตู้เสื้อผ้าติดตั้งไว้ภายใน เช่น
            ราวแขวนผ้าหรือชั้นวางเสื้อผ้า
          </p>
        </div>
        <div class="mt-3" style="text-align: center;">
          <button
            @click="prodboard"
            style="background-color: #EAAD21; color: black; border: none;border-radius: 4px; width: 60%; height: 32px;"
          >
            <img
              style="margin-top: -5px;"
              src="/img/prodboard/LeadingIcon.svg"
              class="w-1 px-2 pr-0"
            />
            <span style="font-family: 'kittithadabold75regular';"
              >เริ่มออกแบบด้วยตัวคุณเอง</span
            >
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4 pb-1">
      <WardrobeTemplate />
    </div>
    <div v-if="!isLoading" class="mb-2">
      <div class="row" v-if="listProject.length > 0">
        <div class="col-12 pt-2 mb-4">
          <div
            style="background-color: #0172B8;height: 58px;position: relative;"
          >
            <p
              style="font-family: 'kittithadabold75regular';font-size:32px; color: white; position: absolute;top: 60%;left: 50%;width: 100%;height: 100%;margin: -27px 0 0 -50%;text-align: center;"
            >
              List Project Prodboard ของ {{ custInfo.CNAME }}
            </p>
          </div>
        </div>
        <div class="col-12" v-if="!isLoading">
          <div
            id="listProject"
            class="row m-0 owl-carousel owl-theme owl-loaded"
            :key="listProject"
          >
            <div
              class="row ml-1 mr-1"
              v-for="project in listProject"
              :key="project.id"
            >
              <!-- <div class="col px-1">{{ project.id }}</div> -->
              <div class="col-12 item">
                <div class="prodboard_list text-center">
                  <img height="200" v-bind:src="project.preview" alt="" />
                </div>

                <p
                  class="text-center"
                  style="margin-bottom:0px;font-family: 'kittithadabold75regular'"
                >
                  {{ project.reference ? project.reference : "-" }}
                </p>
                <p
                  class="text-center"
                  style="margin-bottom:0px;margin-top: -10px;"
                >
                  {{ project.updated }}
                </p>
                <p
                  class="text-center"
                  style="margin-bottom:0px;margin-top: -10px;color: #999999;"
                >
                  (สินค้า {{ totalOrders(project.invoice) }} รายการ)
                </p>
                <div
                  class="text-center"
                  style="margin-bottom:0px;margin-top: -10px;"
                >
                  <button
                    @click="redirectProdboard(project.id)"
                    style="width:80%;background-color: #EAAD21;font-family: 'kittithadathin35regular';font-size:24px; color: black;margin-top: 12px;border-radius: 4px;border: none;padding: 0px 20px;"
                  >
                    <span
                      style="font-family: 'kittithadabold75regular';display: inline; text-align: left; margin-left: 0%; vertical-align:middle;"
                    >
                      รายละเอียด
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading_wait"></div>
  </section>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import WardrobeTemplate from "@/components/Prodboard/WardrobeTemplate.vue";

  export default {
    name: "WardrobeComp",

    data: function() {
      return {
        custInfo: {},
        listProject: {},
        lengthSKU: 0,
        isLoading: true,
      };
    },
    components: {
      WardrobeTemplate,
    },
    mounted() {
      $(".loading_wait").show();
      this.getCustInfo();
      let ref = this.getRef;
      if (ref !== "") {
        if (this.custInfo.VIPID !== "") {
          this.getListProjectProdboard();
        }
      } else {
        if(this.getWSFLAG){
          this.$router.push({ name: "OrderWholeSale" });
        }else{
          this.$router.push({ name: "FindUser" });
        }
      }
    },
    props: [],
    methods: {
      totalOrders: function(values) {
        return values.reduce((acc, val) => {
          return acc + parseInt(val.quantity);
        }, 0);
      },
      async prodboard() {
        try {
          // let data = this.dataToSend;
          // let data = this.getQTCUSTINFO;
          //this.$router.push("/Prodboard");
          this.$router.push({
            name: "Prodboard",
            params: { getProdID: 0 },
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async getCustInfo() {
        try {
          this.custInfo = this.getQTCUSTINFO;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async getListProjectProdboard() {
        try {
          this.isLoading = true;
          let data = this.getQTCUSTINFO;
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/getListProjectProdboard`,
            data,
            "auth"
          );
          let res = getAPI.data.data;
          this.listProject = res;
          // if(res.invoice.length >)
          // lengthSKU
          $(".loading_wait").hide();
          this.isLoading = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async startOwlCarousel() {
        let _this = this;

        $(".owl-carousel").owlCarousel({
          items: 5,
          loop: false,
          nav: true,
          navText: [
            '<i class="fas fa-chevron-left fa" aria-hidden="true"></i>',
            '<i class="fas fa-chevron-right fa" aria-hidden="true"></i>',
          ],
          dots: false,
          autoplay: false,
          slideBy: 5,
          navSpeed: 50,
          responsive: {
            0: {
              items: 2,
              slideBy: 2,
            },
            480: {
              items: 2,
              slideBy: 2,
            },
            768: {
              items: 3,
              slideBy: 3,
            },
            992: {
              items: 4,
              slideBy: 4,
            },
            1400: {
              items: 5,
              slideBy: 5,
            },
          },
        });
        this.isShowMasterTime = true;
      },
      async redirectProdboard(id) {
        try {
          this.$router.push({
            name: "ProdboardDetail",
            params: { getProdID: id },
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      getStoreName: function() {
        return this.$store.getters["service/getQTSTORENAME"];
      },
      getBU: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getBuName: function() {
        let getBU = this.$store.getters["customer/getQTBU"];
        if (getBU == "bnb") {
          return "บ้านแอนด์บียอนด์";
        } else if (getBU == "at1") {
          return "Auto1";
        } else {
          return "ไทวัสดุ";
        }
      },
      getQTCUSTINFO: function() {
        return this.$store.getters["customer/getQTCUSTINFO"];
      },
      getQTUSERID: function() {
        return this.$store.getters["auth/getQTUSERID"];
      },
      getIs10101: {
        get() {
          return this.$store.getters["service/getIs10101"];
        },
        set(value) {
          this.$store.dispatch("service/setIs10101", value);
        },
      },
      getWSFLAG: function () {
        return this.$store.getters['auth/getWSFLAG']
      },
    },
    watch: {
      getStore: function() {
        this.showList();
      },
      getReloadOrderInfo: function() {
        this.showList();
      },
      listProject: function() {
        this.$nextTick(() => {
          this.startOwlCarousel();
        });
      },
    },
  };
</script>

<style>
  #listProject .owl-prev {
    position: absolute;
    top: 40%;
    left: -1rem;
    display: block;
    color: black !important;
    opacity: 0.3;
    z-index: 99;
    cursor: pointer;
    transform: translate(0, -50%);
    background: none !important;
    outline: none !important;
  }
  #listProject .owl-prev.disabled {
    display: none !important;
  }
  #listProject .owl-prev:hover {
    opacity: 0.9;
    background: none;
  }
  #listProject .owl-next {
    position: absolute;
    top: 40%;
    right: -1rem;
    display: block;
    color: black !important;
    opacity: 0.3;
    z-index: 99;
    cursor: pointer;
    transform: translate(0, -50%);
    background: none !important;
    outline: none !important;
  }
  #listProject .owl-next.disabled {
    display: none !important;
  }
  #listProject .owl-next:hover {
    opacity: 0.9;
  }
  .loading_wait {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-image: url("/img/loadsmall.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
</style>
